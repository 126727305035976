import { InjectionToken } from '@angular/core'
import { Criterion } from '@awork/_shared/functions/criteria/types'
import { isAnyCriterionTrue } from '@awork/_shared/functions/criteria/composition.criteria'
import { isDevelopCriterion, isLocalhostCriterion } from '@awork/_shared/functions/criteria/environment.criteria'

export enum Feature {
  ExampleFeature = 'exampleFeature',
  NewRichTextEditorLiveEditing = 'newRichTextEditorLiveEditing',
  NpsToastScheduling = 'npsToastScheduling',
  NewHistoryNavigationButtons = 'newHistoryNavigationButtons',
  SkipAccountServiceUserCheck = 'skipAccountServiceUserCheck'
}

export type FeatureFlags = { [key in Feature]: Criterion[] }
const featureFlags: FeatureFlags = {
  [Feature.ExampleFeature]: [isDevelopCriterion],
  [Feature.NewRichTextEditorLiveEditing]: [() => true],
  [Feature.NewHistoryNavigationButtons]: [() => true],
  [Feature.SkipAccountServiceUserCheck]: [() => true],
  [Feature.NpsToastScheduling]: [isAnyCriterionTrue([isDevelopCriterion, isLocalhostCriterion])]
}

export const FeatureFlagsToken = new InjectionToken<FeatureFlags>('FeatureFlags', {
  providedIn: 'root',
  factory: () => featureFlags
})
