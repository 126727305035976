import { NgIf } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { Size } from '@awork/_shared/types/size'
import { WithGlobals } from '../../../classes/with-globals'
import { InfoBoxComponent } from '../../ui-help/info-box/info-box.component'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'
import { Color } from '@awork/_shared/types/color'

type SwitchButtonSize = Size.s | Size.m

@Component({
  selector: 'aw-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipDirective, NgIf, InfoBoxComponent, ReactiveFormsModule]
})
export class SwitchButtonComponent extends WithGlobals implements OnChanges {
  @Input() size: SwitchButtonSize = Size.m
  @Input() errorText: string
  @Input() control = new FormControl(false)
  @Input() value: boolean
  @Input() direction: 'auto' | 'reverse' = 'auto'
  @Input() tooltip: { on: string; off: string } = {
    on: q.translations.SwitchButtonComponent.on,
    off: q.translations.SwitchButtonComponent.off
  }
  @Input() label: string

  @HostBinding('class.disabled')
  @Input()
  disabled = false

  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>()

  protected translations = q.translations.SwitchButtonComponent
  protected readonly colors = Color

  sizes = Size

  constructor() {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.control.setValue(this.value)
    }
  }

  /**
   * Toggles the value and emits the click event
   */
  clicked(event: MouseEvent): void {
    if (!this.disabled) {
      this.control.setValue(!this.control.value)
      this.changed.emit(this.control.value)
    } else {
      event.preventDefault()
      event.stopPropagation()
    }
  }
}
