import { forkJoin, of, Observable, Subject, merge, throwError, mergeMap, map, takeUntil, catchError, tap } from 'rxjs'
import { Injectable } from '@angular/core'
import { apiEndpoint } from '@awork/environments/environment'
import { ApiClient, QueryParams } from '@awork/_shared/services/api-client/ApiClient'
import { HttpResponse, HttpParams } from '@angular/common/http'
import { Contact, ContactTypes } from '@awork/_shared/models/contact.model'
import { UserStore } from '@awork/features/user/state/user.store'
import { UserQuery } from '@awork/features/user/state/user.query'
import { SignalService } from '@awork/_shared/services/signal-service/signal.service'
import { Absence } from '@awork/features/user/models/absence.model'
import { groupBy } from '@awork/_shared/functions/lodash'
import { Tag, TagOwner } from '@awork/_shared/models/tag.model'
import { formatDateForFilter } from '@awork/_shared/functions/query-params-helper'
import { areDatesOverlapping } from '@awork/_shared/functions/date-operations'
import { User, UserPlanningSettings, UserProjectLastActivity } from '@awork/features/user/models/user.model'
import { HolidayRegion, IHolidayRegionLocation } from '../../models/holiday-region.model'
import { WorkspaceAbsence } from '../../models/workspace-absence.model'

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly url: string
  totalPages = 0
  totalItems = 0

  // Used to know when an user has been created/updated/deleted
  userCreated = new Subject<User>()
  userUpdated = new Subject<User>()
  userDeleted = new Subject<string>()
  userChanged = merge(this.userCreated, this.userUpdated, this.userDeleted)

  fetchCanceled = new Subject<boolean>()

  constructor(
    private apiClient: ApiClient,
    private userStore: UserStore,
    private userQuery: UserQuery,
    private signalService: SignalService
  ) {
    this.url = `${apiEndpoint}/users`

    // Subscribe to signal service
    this.signalService.getSignal('user', userStore, this.userDeleted)

    // Subscribe to absences changes
    this.signalService.getSignal('absence', userStore)
  }

  // ########### ALL USERS ##############

  /**
   * Makes an API call to request the user information
   * @param {string} userId
   * @returns {Observable<User>} - The user information, is null if there is some error (ex.: User not found)
   */
  fetchUser(userId?: string): Observable<User> {
    return this.apiClient.get<User>(userId ? `${this.url}/${userId}` : `${apiEndpoint}/me`).pipe(
      map(user => {
        user = new User(user, 'UserService.fetchUser')
        this.userStore.upsert(user.id, user)

        if (!userId) {
          this.userStore.setCurrentUser(user.id)
        }

        return user
      })
    )
  }

  /**
   * Gets the user from the store and/or the API
   * @param {string} id
   * @returns {Observable<User>}
   */
  getUser(id: string): Observable<User> {
    if (id) {
      let fetched = false
      return this.userQuery.selectUser(id).pipe(
        mergeMap(user => {
          // If the user has been found, return it and make an API call to fetch it fresh (just once)
          if (user) {
            if (!fetched) {
              fetched = true
              this.fetchUser(id).pipe(takeUntil(this.fetchCanceled)).subscribe()
            }
            // else, fetch it from the API (just once)
          } else {
            fetched = true
            return this.fetchUser(id)
          }

          return of(user)
        })
      )
    } else {
      return of(null)
    }
  }

  /**
   * Makes API calls to fetch all users
   * @param {QueryParams} queryParams
   * @returns {Observable<User[]>}
   */
  fetchAllUsers(queryParams: QueryParams = {}): Observable<User[]> {
    let syncStore = false

    if (!queryParams.filterBy) {
      syncStore = true
    }

    queryParams = this.setDefaultQueryParams(queryParams)

    const params: HttpParams = ApiClient.getQueryParams(queryParams)

    return this.apiClient.getAll<User[]>(this.url, { params }).pipe(
      map(users => {
        if (syncStore) {
          this.userStore.sync(users, this.userQuery.getAllUsers())
        }

        return users.map(user => new User(user, 'UserService.fetchAllUsers'))
      })
    )
  }

  /**
   * Fetches the last activity of external users in their connected projects
   * @returns {Observable<UserProjectLastActivity[]>}
   */
  fetchExternalUsersLastActivity(): Observable<UserProjectLastActivity[]> {
    return this.apiClient.get<UserProjectLastActivity[]>(`${this.url}/external/activities/last`)
  }

  /**
   * Makes an API call to update the user
   * @param {User} user - User to be updated
   * @param {boolean} self - True if is the current user, false otherwise
   * @returns {Observable<User>}
   */
  updateUser(user: User, self = false): Observable<User> {
    const operationId = this.userStore.history.startOperation({
      type: 'update',
      entities: [user]
    })

    this.userStore.update(user.id, user)

    return this.apiClient.put<User>(`${this.url}/${user.id}/`, user).pipe(
      map(updatedUser => {
        user.updatedOn = updatedUser.updatedOn
        user.resourceVersion = updatedUser.resourceVersion
        this.userStore.update(user.id, user)
        this.userStore.history.endOperation(operationId)
        return user
      }),
      catchError(error => {
        this.userStore.history.undo(operationId)
        return throwError(error)
      })
    )
  }

  /**
   * Makes an API call to delete the user
   * @param {User} user - The user to be deleted
   * @returns {Observable<string>}
   */
  deleteUser(user: User): Observable<string> {
    return this.apiClient.delete<string>(`${this.url}/${user.id}`).pipe(
      map(d => {
        this.userStore.remove(user.id)
        this.userDeleted.next(user.id)
        return d
      })
    )
  }

  // ########### USERS CONTACT INFO ##############

  /**
   * Makes an API call to request the user contact informations
   * @param {string} userId
   * @returns {Observable<Contact[]>} - The user information, is null if there is some error (ex.: User not found)
   */
  fetchContactInfo(userId: string): Observable<Contact[]> {
    return this.apiClient
      .get<Contact[]>(`${this.url}/${userId}/contactinfo`)
      .pipe(map(contacts => contacts.map(contact => new Contact(contact))))
  }

  /**
   * Makes API calls to create/update/delete contact information
   * @param {Contact[]} contactInfos
   * @param {Contact[]} deletedContactInfos
   * @param {User} user
   */
  manageContactInfos(contactInfos: Contact[], deletedContactInfos: Contact[], user: User): Promise<void> {
    const calls$: Observable<Contact>[] = []
    const deleteCalls$: Observable<string>[] = []

    contactInfos.forEach(contactInfo => {
      calls$.push(this.sendContactInfo(user.id, contactInfo))
    })

    deletedContactInfos.forEach(contactInfo => {
      deleteCalls$.push(this.deleteContactInfo(user.id, contactInfo))
    })

    const callsFinishedSubject$ = new Subject<void>()

    forkJoin(calls$).subscribe(updatedContactInfos => {
      user.userContactInfos = updatedContactInfos.map(contact => new Contact(contact))
      this.userStore.update(user.id, user)
      this.userUpdated.next(user)
      callsFinishedSubject$.next()
    })

    forkJoin(deleteCalls$).subscribe(() => {
      if (contactInfos.length === 0) {
        user.userContactInfos = []
        this.userStore.update(user.id, user)
        this.userUpdated.next(user)
        callsFinishedSubject$.next()
      }
    })

    const numberOfCalls = (calls$.length > 0 ? 1 : 0) + (deleteCalls$.length > 0 ? 1 : 0)
    let finishedCalls = 0
    return new Promise<void>((resolve, reject) => {
      callsFinishedSubject$.subscribe(() => {
        finishedCalls++
        if (finishedCalls === numberOfCalls) {
          resolve()
        }
      })
    })
  }

  /**
   * Makes an API call to send contact information
   * @param {string} userId
   * @param {Contact} contactInfo - contactInfo to be created
   * @returns {Observable<Contact>}
   */
  private sendContactInfo(userId: string, contactInfo: Contact): Observable<Contact> {
    contactInfo.isAddress = <any>ContactTypes.address === contactInfo.type

    if (!contactInfo.id) {
      return this.apiClient.post<Contact>(`${this.url}/${userId}/contactinfo`, contactInfo)
    } else {
      return this.apiClient.put<Contact>(`${this.url}/${userId}/contactinfo/${contactInfo.id}`, contactInfo)
    }
  }

  /**
   * Makes an API call to delete the user's contact information
   * @param {string} userId
   * @param {Contact} contactInfo - contactInfo to be deleted
   * @returns {Observable<string>}
   */
  private deleteContactInfo(userId: string, contactInfo: Contact): Observable<string> {
    return this.apiClient.delete<string>(`${this.url}/${userId}/contactinfo/${contactInfo.id}`)
  }

  // ########### USER STATUS ##############

  /**
   * Makes an API call to update the user status
   * @param {User} user - The user to be updated
   * @param {string} status - The user status
   * @returns {Observable<User>}
   */
  updateUserStatus(user: User, status: string): Observable<User> {
    let url: string
    switch (status) {
      case 'activate':
        url = `${this.url}/${user.id}/activate`
        break
      case 'deactivate':
        url = `${this.url}/${user.id}/deactivate`
        break
    }

    return this.apiClient.post(url, {}).pipe(
      map(() => {
        user.status.isActivated = status === 'activate'
        user.isDeactivated = status === 'deactivate'
        this.userStore.update(user.id, user)
        return user
      })
    )
  }

  // ########### USER TAGS ##############

  /**
   * Makes an API call to get the tags available for users
   * @returns {Observable<Tag[]>}
   */
  fetchTags(): Observable<Tag[]> {
    return this.apiClient.get<Tag[]>(`${this.url}/tags`).pipe(
      // TODO: Remove mapping when migration is done
      map(tags => {
        if (typeof tags?.[0] === 'string') {
          return tags.map(tag => ({ name: (<unknown>tag) as string }))
        } else {
          return tags
        }
      })
    )
  }

  /**
   * Makes API calls to add/delete tags
   * @param {any} owner
   * @param {Tag[]} tags
   * @param {Tag[]} addedTags
   * @param {Tag[]} removedTags
   * @returns {Observable<Tag[]>}
   */
  manageTags(owner: TagOwner, tags: Tag[], addedTags: Tag[], removedTags: Tag[]): Observable<Tag[]> {
    const calls$: Observable<void>[] = []
    const user = owner as User

    if (addedTags.length > 0) {
      calls$.push(this.sendTags(user, addedTags))
    }
    if (removedTags.length > 0) {
      calls$.push(this.deleteTags(user, removedTags))
    }

    return forkJoin(calls$).pipe(
      map(() => {
        user.tags = tags
        this.userStore.update(user.id, user)
        this.userUpdated.next(user)
        return tags
      })
    )
  }

  /**
   * Makes an API call to add tags to an user
   * @param {User} user - The user to be updated
   * @param {Tag[]} tags - The tags to be added
   * @returns {Observable<void>}
   */
  private sendTags(user: User, tags: Tag[]): Observable<void> {
    return this.apiClient.post<void>(`${this.url}/${user.id}/addtags`, tags)
  }

  /**
   * Makes an API call to delete tags of an user
   * @param {User} user - The user to be updated
   * @param {Tag[]} tags - The tags to be deleted
   * @returns {Observable<void>}
   */
  private deleteTags(user: User, tags: Tag[]): Observable<void> {
    return this.apiClient.post<void>(`${this.url}/${user.id}/deletetags`, tags)
  }

  /**
   * Makes an API call to delete the tag for all users
   * @param {Tag} tag - The tag to be deleted
   * @returns {Observable<void>}
   */
  deleteTagGlobally(tag: Tag): Observable<void> {
    return this.apiClient.post<void>(`${this.url}/deletetags`, tag)
  }

  /**
   * Makes an API call to rename a tag of an user
   * @param {Tag} tag - old tag value
   * @param {Tag} renameTag - new tag value
   * @returns {Observable<string>}
   */
  renameTag(tag: Tag, renameTag: Tag): Observable<Tag> {
    return this.apiClient.post<Tag>(`${this.url}/renametag`, {
      newTagValue: renameTag,
      oldTagValue: tag
    })
  }

  /**
   * Makes an API call to update the user's tag
   * @param {Tag} tag
   * @param {Tag} oldTag
   * @param {string} entityId
   */
  updateTag(tag: Tag, oldTag: Tag, entityId: string): Observable<Tag> {
    return this.apiClient.post<Tag>(`${this.url}/${entityId}/updatetags`, {
      oldTagName: oldTag.name,
      newTag: tag
    })
  }

  // ########### USER COUNT ##############

  /**
   * Makes an API call to request the total count of users for a given filter
   * @param {QueryParams} queryParams
   * @returns {Observable<number>} - Observable of a number
   */
  fetchUserCount(queryParams?: QueryParams): Observable<number> {
    let params: HttpParams = ApiClient.getQueryParams(queryParams)
    params = params.append('count', 'true')

    const call$ = this.apiClient.get<HttpResponse<User[]>>(this.url, {
      params,
      observe: 'response' // To get the full response and get access to the headers (pagination info)
    })

    return call$.pipe(
      map((res: HttpResponse<User[]>) => {
        return Number(res.headers.get('aw-totalitems'))
      })
    )
  }

  /**
   * Returns the count of all active users (not deactivated), which count the workspace's users quota
   * @returns {Observable<number>}
   */
  getActiveUserCount(): Observable<number> {
    return this.fetchUserCount({ filterBy: `isDeactivated eq false` })
  }

  // ################# ABSENCES #################

  /**
   * Returns all absences for all users
   * @param {{userIds?: string[], startOn?: Date, endOn?: Date}} filter
   * @returns {Observable<Absence[]>}
   */
  fetchAbsences(filter?: { userIds?: string[]; startOn?: Date; endOn?: Date }): Observable<Absence[]> {
    const queryParams: QueryParams = { pageSize: 50000 }

    const params: HttpParams = ApiClient.getQueryParams(queryParams)

    const daysOffParams = {
      params,
      userIds: filter?.userIds || null,
      from: filter?.startOn || null,
      to: filter?.endOn || null
    }

    return this.apiClient.post<Absence[]>(`${apiEndpoint}/users/daysOff/query`, daysOffParams).pipe(
      tap(absences => {
        Object.entries(groupBy(absences, absence => absence.userId)).forEach(userGroup => {
          const user = this.userQuery.getEntity(userGroup[0])

          // User is not set in the case of deletion
          if (user) {
            this.userStore.update(user.id, storedUser => {
              // If a date range is provided, sync the store by removing the store absences
              // from this date range and adding the ones received from the API
              if (filter?.startOn && filter?.endOn) {
                const filteredAbsences =
                  storedUser.absences?.filter(
                    absence =>
                      !areDatesOverlapping(
                        { start: absence.startOn, end: absence.endOn },
                        { start: filter.startOn, end: filter.endOn }
                      )
                  ) || []
                return { absences: [...filteredAbsences, ...userGroup[1]] }
              } else {
                return { absences: userGroup[1] }
              }
            })
          }
        })
      }),
      map(absences => {
        return absences.map(a => Absence.mapAbsence(a))
      })
    )
  }

  /**
   * Removes all of the region absences from the selected users in the store
   * @param {HolidayRegion} holidayRegion
   * @param {string[]} userIds
   */
  removeRegionAbsencesFromUsers(holidayRegion: HolidayRegion, userIds: string[]): void {
    userIds.forEach(userId => {
      const user = this.userQuery.getUser(userId)

      if (user.absences) {
        user.absences = user.absences.filter(absence => absence.id !== holidayRegion.id)
        this.userStore.update(user.id, { absences: user.absences })
      }
    })
  }

  /**
   * Fetches a absence by the id
   * @param absenceId
   */
  fetchAbsence(absenceId: string): Observable<Absence> {
    return this.apiClient.get<Absence>(`${apiEndpoint}/absences/${absenceId}`).pipe(
      map(a => {
        const user = this.userQuery.getEntity(a.userId)
        if (user) {
          if (!user.absences) {
            user.absences = []
          }
          user.absences = user.absences.filter(userAbsence => userAbsence.id !== a.id)
          user.absences.push(a)
          this.userStore.update(user.id, { absences: user.absences })
        }
        return Absence.mapAbsence(a)
      })
    )
  }

  /**
   * Send a new absence to the API and saves it in the user store
   * @param absence
   */
  sendAbsence(absence: Absence): Observable<Absence> {
    const absencePost = {
      ...absence,
      startOn: formatDateForFilter(absence.startOn),
      endOn: formatDateForFilter(absence.endOn)
    }

    return this.apiClient.post<Absence>(`${apiEndpoint}/absences`, absencePost).pipe(
      map(a => {
        const user = this.userQuery.getEntity(absencePost.userId)
        if (user) {
          if (!user.absences) {
            user.absences = []
          }
          user.absences.push(a)
          this.userStore.update(user.id, { absences: user.absences })
        }
        return Absence.mapAbsence(a)
      })
    )
  }

  /**
   * Updates the given absence to the API and updates it also in the user store
   * @param absence
   */
  updateAbsence(absence: Absence): Observable<Absence> {
    const absencePost = {
      ...absence,
      startOn: formatDateForFilter(absence.startOn),
      endOn: formatDateForFilter(absence.endOn)
    }

    return this.apiClient.put<Absence>(`${apiEndpoint}/absences/${absencePost.id}`, absencePost).pipe(
      map(a => {
        const user = this.userQuery.getEntity(absencePost.userId)
        if (user) {
          user.absences = user.absences || []
          user.absences = user.absences.filter(userAbsence => userAbsence.id !== absencePost.id)
          user.absences.push(a)
          this.userStore.update(user.id, { absences: user.absences })
        }
        return Absence.mapAbsence(a)
      })
    )
  }

  /**
   * Delete absence
   * @param absence
   */
  deleteAbsence(absence: Absence): Observable<string> {
    return this.apiClient.delete<string>(`${apiEndpoint}/absences/${absence.id}`).pipe(
      map(a => {
        const user = this.userQuery.getEntity(absence.userId)
        if (user) {
          if (!user.absences) {
            user.absences = []
          }
          user.absences = user.absences.filter(userAbsence => userAbsence.id !== absence.id)
          this.userStore.update(user.id, { absences: user.absences })
        }
        return a
      })
    )
  }

  // ################# Absence Regions #################

  /**
   * Fetches holiday Regions
   * @returns {Observable<HolidayRegion[]>}
   */
  fetchHolidayRegions(): Observable<HolidayRegion[]> {
    return this.apiClient.get<HolidayRegion[]>(`${apiEndpoint}/absenceregions`).pipe(
      map(holidayRegions => {
        holidayRegions.forEach(region => {
          if (region.assignedUsers?.length) {
            this.userStore.update(region.assignedUsers, { holidayRegion: region })
          }
        })

        return holidayRegions.map(region => new HolidayRegion(region))
      })
    )
  }

  /**
   * Assigns users to a holiday region and updates the user store
   * @param {HolidayRegion} holidayRegion
   * @param {string[]} userIds
   * @returns {Observable<void>}
   */
  assignUsersToHolidayRegion(holidayRegion: HolidayRegion, userIds: string[]): Observable<void> {
    const postBody = { regionId: holidayRegion.id, userIds }

    return this.apiClient.put<void>(`${apiEndpoint}/absenceregions/users/assign`, postBody).pipe(
      tap(() => {
        const usersPreviouslySelected = this.userQuery.getUsersByHolidayRegion(holidayRegion.id)
        const newUserIdsSet = new Set(userIds)

        // Remove the holidayRegion from the users that are not selected
        usersPreviouslySelected
          .filter(user => !newUserIdsSet.has(user.id))
          .forEach(user => {
            this.userStore.update(user.id, { holidayRegion: null })
          })

        // Add the holidayRegion to the users that are selected
        this.userStore.update(userIds, { holidayRegion })
      })
    )
  }

  /**
   * Assigns the current user to a holiday region and updates the user store
   * @param {HolidayRegion} holidayRegion
   * @returns {Observable<void>}
   */
  assignUserToHolidayRegion(holidayRegion?: HolidayRegion): Observable<void> {
    const postBody = { regionId: holidayRegion?.id || null }

    return this.apiClient.put<void>(`${apiEndpoint}/absenceregions/me/assign`, postBody).pipe(
      tap(() => {
        const currentUser = this.userQuery.getCurrentUser()
        this.userStore.update(currentUser.id, { holidayRegion })
      })
    )
  }

  /**
   * Fetches all the holiday countries available
   * @returns {Observable<IHolidayRegionLocation[]>}
   */
  fetchHolidayCountries(): Observable<IHolidayRegionLocation[]> {
    return this.apiClient.get<IHolidayRegionLocation[]>(`${apiEndpoint}/absenceregions/countries`)
  }

  /**
   * Fetches all the holiday locations available for a given country
   * @param {string} countryCode
   * @returns {Observable<IHolidayRegionLocation[]>}
   */
  fetchHolidayLocations(countryCode: string): Observable<IHolidayRegionLocation[]> {
    return this.apiClient.get<IHolidayRegionLocation[]>(
      `${apiEndpoint}/absenceregions/countries/${countryCode}/locations`
    )
  }

  /**
   * Saves the given holiday region to the API
   * @param holidayRegion
   * @returns {Observable<HolidayRegion>}
   */
  saveHolidayRegion(holidayRegion: HolidayRegion): Observable<HolidayRegion> {
    const { name, countryCode, locationCode, isDefault } = holidayRegion
    const body = { name, countryCode, locationCode, isDefault: !!isDefault }

    return this.apiClient.post<HolidayRegion>(`${apiEndpoint}/absenceregions`, body)
  }

  /**
   * Delete holiday region
   * @param {HolidayRegion} holidayRegionId
   * @returns {Observable<string>}
   */
  deleteHolidayRegion(holidayRegionId: HolidayRegion['id']): Observable<string> {
    return this.apiClient.delete<string>(`${apiEndpoint}/absenceregions/${holidayRegionId}`)
  }

  /**
   * Updates the given holiday region to the API
   * @param holidayRegion
   * @returns {Observable<HolidayRegion>}
   */
  updateHolidayRegion(holidayRegion: HolidayRegion): Observable<HolidayRegion> {
    const { id, name, countryCode, locationCode, isDefault } = holidayRegion
    const body = { id, name, countryCode, locationCode, isDefault: !!isDefault }

    return this.apiClient
      .put<HolidayRegion>(`${apiEndpoint}/absenceregions/${holidayRegion.id}`, body)
      .pipe(map(holidayRegion => new HolidayRegion(holidayRegion)))
  }

  /**
   * Fetches workspace Absences
   * @returns {Observable<WorkspaceAbsence[]>}
   */
  fetchWorkspaceAbsences(): Observable<WorkspaceAbsence[]> {
    return this.apiClient.get<WorkspaceAbsence[]>(`${apiEndpoint}/workspaceabsences`).pipe(
      map(workspaceAbsences =>
        workspaceAbsences.map(workspaceAbsence => {
          return new WorkspaceAbsence(workspaceAbsence)
        })
      )
    )
  }

  /**
   * Saves the given workspace absence to the API
   * @param {WorkspaceAbsence} workspaceAbsence
   * @returns {Observable<WorkspaceAbsence>}
   */
  saveWorkspaceAbsence(workspaceAbsence: WorkspaceAbsence): Observable<WorkspaceAbsence> {
    const { endOn, startOn, description, isHalfDayOnStart, isHalfDayOnEnd, regionId } = workspaceAbsence
    const body = { endOn, startOn, description, isHalfDayOnStart, isHalfDayOnEnd, regionId: regionId || null }

    return this.apiClient.post<WorkspaceAbsence>(`${apiEndpoint}/workspaceabsences`, body)
  }

  /**
   * updates the given workspace absence to the API
   * @param {WorkspaceAbsence} workspaceAbsence
   * @returns {Observable<WorkspaceAbsence>}
   */
  updateWorkspaceAbsence(workspaceAbsence: WorkspaceAbsence): Observable<WorkspaceAbsence> {
    const { id, endOn, startOn, description, isHalfDayOnStart, isHalfDayOnEnd, regionId } = workspaceAbsence
    const body = { id, endOn, startOn, description, isHalfDayOnStart, isHalfDayOnEnd, regionId: regionId || null }

    return this.apiClient.put<WorkspaceAbsence>(`${apiEndpoint}/workspaceabsences/${workspaceAbsence.id}`, body)
  }

  /**
   * Delete workspace absence
   * @param {WorkspaceAbsence} workspaceAbsenceId
   * @returns {Observable<string>}
   */
  deleteWorkspaceAbsence(workspaceAbsenceId: WorkspaceAbsence['id']): Observable<string> {
    return this.apiClient.delete<string>(`${apiEndpoint}/workspaceabsences/${workspaceAbsenceId}`)
  }

  fetchUserCapacity(user: User): Observable<User> {
    return this.apiClient.get<UserPlanningSettings>(`${apiEndpoint}/users/${user.id}/capacity`).pipe(
      map(planningSettings => {
        user.capacityPerWeek = planningSettings.weeklyCapacity
        this.userStore.update(user.id, { capacityPerWeek: user.capacityPerWeek })

        return user
      }),
      catchError(error => (error.status === 404 ? of(user) : throwError(error)))
    )
  }

  /**
   * Fetches the capacities for the given users
   * @param {User[]} users
   * @returns {Observable<UserPlanningSettings[]>}
   */
  fetchUsersCapacities(users: User[]): Observable<UserPlanningSettings[]> {
    return this.apiClient.get<UserPlanningSettings[]>(`${apiEndpoint}/users/capacities`).pipe(
      map(planningSettings => {
        const planningSettingsMap = new Map(
          planningSettings?.map(setting => [setting?.userId, setting?.weeklyCapacity]) || []
        )

        const usersIds = planningSettings.map(user => user.userId)
        this.userStore.update(usersIds, userState => ({
          capacityPerWeek: planningSettingsMap.get(userState.id)
        }))

        return planningSettings
      }),
      catchError(error => (error.status === 404 ? of() : throwError(error)))
    )
  }

  /**
   * Update user's capacity per week
   * @param user
   * @returns {Observable<void>}
   */
  updateCapacity(user): Observable<void> {
    this.userStore.update(user.id, { capacityPerWeek: user.capacityPerWeek })

    return this.apiClient.put(`${apiEndpoint}/users/${user.id}/capacity`, user.capacityPerWeek)
  }

  /**
   * Sets a flag which will be used to determine if the user should be migrated to awork connect
   * @param {string} userId
   * @param {boolean} shouldMigrateToConnect
   * @returns {Observable<void>}
   */
  setShouldMigrateToConnect(userId: string, shouldMigrateToConnect: boolean): Observable<void> {
    return this.apiClient.post(`${apiEndpoint}/users/${userId}/setShouldMigrateToConnect`, { shouldMigrateToConnect })
  }

  // ########### SET QUERY PARAMS ##############

  /**
   * Sets the default query params for the users query
   * @param {QueryParams} queryParams
   * @return {QueryParams}
   */
  private setDefaultQueryParams(queryParams: QueryParams): QueryParams {
    if (!queryParams) {
      queryParams = {}
    }

    if (!queryParams.pageSize) {
      queryParams.pageSize = 1000
    }

    if (!queryParams.page) {
      queryParams.page = 1
    }

    if (!queryParams.orderBy) {
      queryParams.orderBy = 'lastName,firstName asc'
    }

    return queryParams
  }
}
